import React, { useState, useEffect } from 'react';
import { requestWithAuth } from '../fetch.js';

const SupplyInfo = (props) => {
    const [resources, setResources] = useState(null);

    async function fetchInitialResources() {
        const cacheRes = await requestWithAuth('reports/cacheResources?type=gravite');
        setResources(cacheRes);
    }

    useEffect(() => {
        fetchInitialResources();
    }, [])

    function getDisabled(arr) {
        if (arr && arr.length) {
            const res = arr.filter(a => a.disabled == true || a.isActive == false)
            return res.length
        }
        return 0;
    }

    if (resources) {
        const keys = Object.keys(resources)
        return (
            <div style={{ width: '90%', margin: 'auto' }}>
                <table className='table tbl table-striped table-bordered'>
                    <thead><tr><th>Name</th><th>Items</th><th>Disabled</th></tr></thead>
                    <tbody>{keys.map((rows, index) => <tr key={rows}><td>{rows}</td><td>{resources[rows] ? resources[rows].length : 'N/A'}</td><td>{getDisabled(resources[rows])}</td></tr>)}</tbody>
                </table>
            </div>
        )
    } else {
        return <p>Loading...</p>
    }
};

const Wrapper = () => {
    return (<SupplyInfo />);
}

export default Wrapper